@font-face {
    font-family: 'icons';
    src: url('/themes/site/assets/fonts/icons.eot?9k1dc');
    src: url('/themes/site/assets/fonts/icons.eot?9k1dc#iefix') format('embedded-opentype'),
    url('/themes/site/assets/fonts/icons.ttf?9k1dc') format('truetype'),
    url('/themes/site/assets/fonts/icons.woff?9k1dc') format('woff'),
    url('/themes/site/assets/fonts/icons.svg?9k1dc#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
    content: "\e900";
}

.icon-twitter:before {
    content: "\e901";
}

.icon-linked-in:before {
    content: "\e902";
}

.icon-down-arrow:before {
    content: "\e904";
}

.icon-magnify:before {
    content: "\e903";
}

// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

/**
  Font size maps
 */
$header-sizes: (
    xx-large: (
        line-height: '',
        font-size: 5rem
    ),
    x-large: (
        line-height: '',
        font-size: 3rem
    ),
    large: (
        line-height: '',
        font-size: 2.2rem
    ),
    regular: (
        line-height: '',
        font-size: 2rem
    ),
    small: (
        line-height: '',
        font-size: 1.85rem
    ),
    x-small: (
        line-height: 1.25em,
        font-size: 1.5rem
    ),
    xx-small: (
        line-height: '',
        font-size: 1.4rem
    ),
);

$body-sizes: (
    xx-large: (
        line-height: '',
        font-size: 5rem
    ),
    x-large: (
        line-height: '',
        font-size: 1.222rem
    ),
    large: (
        line-height: '',
        font-size: 1.125rem
    ),
    regular: (
        line-height: 1.525em,
        font-size: 1rem
    ),
    small: (
        line-height: 1.55em,
        font-size: 0.9rem
    ),
    x-small: (
        line-height: 1.3em,
        font-size: 0.8rem
    ),
    xx-small: (
        line-height: '',
        font-size: 0.7rem
    )
);

@function body-font-size($key: "regular", $breakpoint: "") {
    @return map-get(map-get($body-sizes, $key), 'font-size');
}

@function body-line-height($key: "regular", $breakpoint: "") {
    @return map-get(map-get($body-sizes, $key), 'line-height');
}

/**
  Font size helpers
 */
@function header-font-size($key: "regular", $breakpoint: "") {
    @return map-get(map-get($header-sizes, $key), 'font-size');
}

@function header-line-height($key: "regular", $breakpoint: "") {
    @return map-get(map-get($header-sizes, $key), 'line-height');
}

/**
  Header and body size responsive utilities
 */
@each $header-size, $val in $header-sizes {
    .#{$header-size}-header-font {
        @each $property, $value in $val {
            #{$property} : #{$value} !important;
        }
    }

    @include media-breakpoint-down(md) {
        .#{$header-size}-header-font-md {
            @each $property, $value in $val {
                #{$property} : #{$value} !important;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .#{$header-size}-header-font-sm {
            @each $property, $value in $val {
                #{$property} : #{$value} !important;
            }
        }
    }
}

@each $body-size, $val in $body-sizes {
    .#{$body-size}-body-font {
        @each $property, $value in $val {
            #{$property} : #{$value} !important;;
        }
    }

    @include media-breakpoint-down(md) {
        .#{$body-size}-body-font-md {
            @each $property, $value in $val {
                #{$property} : #{$value} !important;;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .#{$body-size}-body-font-sm {
            @each $property, $value in $val {
                #{$property} : #{$value} !important;
            }
        }
    }
}
