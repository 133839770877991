body {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #283745e0;
        opacity: 0;
        pointer-events: none;
        transition: opacity ease 0.5s;
        z-index: 5;
    }

    &.mobile-nav-open, &.desktop-nav-open {
        overflow: hidden;

        &:after {
            opacity: 1;
        }
    }
}

body.scrolling {
    &.mobile-nav-open {
        .fixed-header {
            background: none;
            border-bottom: transparent 0.5rem solid;
        }
    }

    .fixed-header {
        background-color: #283745f0;
        border-bottom: $red 0.5rem solid;

        @include media-breakpoint-down(md) {
            padding: 1.5rem 0;
        }
    }
}

.fixed-header {
    border-bottom: transparent 0.5rem solid;
    background-color: transparent;
    padding: 2.5rem 0;
    position: fixed;
    transition: 0.5s border-bottom cubic-bezier(0.215, 0.61, 0.355, 1),
    0.5s background-color cubic-bezier(0.215, 0.61, 0.355, 1),
    0.35s padding ease;
    z-index: 11;

    @include media-breakpoint-up(md) {
        padding: 3rem 0;
    }

    .container-fluid {
        position: relative;
        z-index: 1;
    }

    .logo {
        display: block;
        max-width: 13rem;
        margin-bottom: 0;
        margin-top: 0;
        position: fixed;
        left: 0;
    }

    .search-toolbar {
        left: 0;
    }

    .sub-link {
        line-height: 0.8em;
        position: relative;

        &.active {
            &:after {
                content: "";
                display: block;
                height: 0.85rem;
                width: 1px;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                background: #ddd;
                left: 0;
            }
        }
    }

    .sub-menu {
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        pointer-events: none;

        a {
            overflow: hidden;
            position: relative;

            span {
                transform: translateY(-150%);
                position: relative;
                display: block;
                transition: transform 0.75s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }

        &.active {
            opacity: 1;
            z-index: 1;
            pointer-events: all;

            a {
                span {
                    transform: translateY(0);
                }
            }
        }
    }

    #menu-toggle {
        cursor: pointer;
        float: right;
        height: 35px;
        margin: 0;
        width: 35px;
        position: fixed;
        right: 1rem;

        @include media-breakpoint-up(md) {
            position: relative;
            right: auto;
        }

        span {
            display: block;
            background: #fff;
            border-radius: 2px;
        }

        #hamburger {
            position: absolute;
            height: 100%;
            width: 100%;

            span {
                width: 100%;
                height: 4px;
                position: relative;
                top: 3px;
                margin: 0 0 7px 0;
                transition: .2s ease-in-out;
                box-sizing: border-box;

                &:nth-child(1) {
                    transition-delay: .5s;
                    width: 70%;
                    left: 30%;
                }

                &:nth-child(2) {
                    transition-delay: .625s;
                }

                &:nth-child(3) {
                    transition-delay: .75s;
                    width: 65%;
                    left: 35%;
                }
            }
        }

        #cross {
            height: 100%;
            position: absolute;
            transform: rotate(45deg);
            width: 100%;

            span {
                transition: .2s ease-in-out;
                box-sizing: border-box;

                &:nth-child(1) {
                    height: 0;
                    width: 4px;
                    position: absolute;
                    top: 0;
                    left: 46%;
                    transition-delay: 0s;
                }

                &:nth-child(2) {
                    width: 0;
                    height: 4px;
                    position: absolute;
                    left: 0;
                    top: 46%;
                    transition-delay: 0.25s;
                }
            }
        }

        &.open {
            #hamburger {
                span {
                    transition: .2s ease-in-out;
                    box-sizing: border-box;
                    width: 0;

                    &:nth-child(1) {
                        transition-delay: 0s;
                    }

                    &:nth-child(2) {
                        transition-delay: .1s;
                    }

                    &:nth-child(3) {
                        transition-delay: .125s;
                    }
                }
            }

            #cross {
                span {
                    &:nth-child(1) {
                        height: 100%;
                        transition-delay: .35s;
                    }

                    &:nth-child(2) {
                        width: 100%;
                        transition-delay: .45s;
                    }
                }
            }
        }
    }

    .desktop-nav {

        .display-desktop {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }

        .display-mobile-and-scrolled {
            display: block;
            width: 100%;

            @include media-breakpoint-up(lg) {
                display: none;

                &.visible {
                    display: block;
                }
            }
        }

        a {
            color: #fff;
            transition: color 800ms cubic-bezier(0.215, 0.61, 0.355, 1);

            &:hover {
                text-decoration: none;
            }
        }

        .desktop-nav__item-container {
            display: inline-block;
        }

        .desktop-nav__item {

            &.active {
                > a {
                    color: $primary;
                }
            }

            > a {
                margin-bottom: 0.35rem;
                line-height: 1.5em;
                position: relative;
                z-index: 1;

                &:hover {
                    color: $primary;
                }

                @include media-breakpoint-up(lg) {
                    font-size: 1rem;
                }

                @include media-breakpoint-up(xl) {
                    font-size: 1.25rem;
                }
            }

            .desktop-nav__hover-nav-content {
                height: 60vh;
                position: absolute;
                width: 100%;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            .desktop-nav__hover-nav {
                background-color: $secondary;
                height: 100vh;
                left: 0;
                position: fixed;
                top: -100vh;
                transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
                width: 100%;
                z-index: 0;

                a {
                    line-height: 1.3em;
                    opacity: 0;
                    transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
                }
            }

            &.active {
                .desktop-nav__hover-nav {
                    top: 0;

                    a {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
