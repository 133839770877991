.btn-primary {
    @include button-variant(
            $primary,
            $primary,
        $hover-background: darken($primary, 7.5%),
        $hover-border: darken($primary, 10%),
        $active-background: darken($primary, 10%),
        $active-border: darken($primary, 12.5%)
    );

    &-inverse {
        @include button-variant(
                $white,
                $primary,
            $hover-background: $primary,
            $hover-border: $primary,
            $active-background: $primary,
            $active-border: $primary
        );
    }
}

.btn-secondary {
    @include button-variant(
            transparent,
            $secondary,
        $hover-background: darken($secondary, 7.5%),
        $hover-border: darken($secondary, 10%),
        $active-background: darken($secondary, 10%),
        $active-border: darken($secondary, 12.5%)
    );

    color: $secondary;
    border-width: 3px;
    font-weight: 700;
}

.btn {
    &.btn-huge {
        font-size: 1rem;
        font-weight: 600;
        line-height: 2em;
        text-transform: uppercase;
    }

    &.btn-small {

    }

    &.btn-with-icon {
        line-height: 1.2em;
    }

    &.btn-overlayed {
        left: 0;
        margin: auto;
        right: 0;
        position: absolute;
    }

    &.btn-full-width {
        width: 100%;

        @include media-breakpoint-up(md) {
            left: auto;
        }

        @include media-breakpoint-up(xl) {
            max-width: none;
            left: -2.5rem;
            width: calc(100% + 5rem);
        }
    }
}

.arrow-link {
    display: block;
    line-height: 1em;
    margin: 1rem 0 3rem 0;
    padding-left: 1.25rem;
    position: absolute;
    transition: padding-left 1s ease;

    .arrow-link__link {
        &:hover {
            text-decoration: none;
        }
    }

    i {
        color: $secondary;
        font-size: 1.5rem;
    }

    &.arrow-link--white {
        i {
            color: $white;
        }
    }

    &.arrow-link--secondary {
        i {
            color: $secondary;
        }
    }

    &.left-position {
        left: -11px;
    }

    &:after {
        content: "";
        display: block;
        height: 100%;
        width: 0.75rem;
        background-color: $primary;
        top: 0;
        left: 0;
        position: absolute;
        transition: width 1s ease;
    }

    &:hover {
        text-decoration: none;
    }
}

.toggle-buttons {
    font-family: $bodyFont;

    .btn {
        font-size: 1.25em;
        font-weight: 600;

        &.btn-primary-inverse {
            color: rgba(179, 60, 83, 0.5);
            border: solid 3px rgba(179, 60, 83, 0.5);
        }
    }
}

.full-width-button {
    font-size: 1rem;
    position: relative;
    z-index: 3;

    &:after {
        background-color: $primary;
        bottom: 0;
        content: "";
        display: block;
        height: 0.25rem;
        margin: auto;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &.has-icon {

        .full-width-button__button {
            max-width: 100%;
            margin: 0 auto;

            span {
                display: block;
                font-weight: bold;
                width: 75%;
                line-height: 1.2em;
                margin: 0 auto;

                i {
                    bottom: 0;
                    font-size: 1.5rem;
                    height: 1.6rem;
                    margin: auto;
                    position: absolute;
                    top: 0;
                    right: 3rem;

                    @include media-breakpoint-up(md) {
                        font-size: 1.5rem;
                        height: 1.6rem;
                        right: auto;
                    }
                }
            }

            @include media-breakpoint-up(md) {
                display: inline;
            }
        }
    }

    .full-width-button__button {
        padding: 0.8rem;
        transform: translateY(-50%);
        z-index: 2;
    }
}
