.large-title-beside-content {

    h2, .h2, h1, .h1 {
        @include font-size(4rem);
        font-weight: 600;

        em {
            font-weight: 500;
        }
    }

    &.dark {
        background: $secondary;
        color: $white;
    }

    &.light {
        background: $gray;
        color: $secondary;
    }

    &.white {
        background: $white;
        color: $secondary;
    }
}
