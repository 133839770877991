.content-grid-link {
    &:hover {
        text-decoration: none;
    }

    .story-listing-item {
        background-position: center;
        background-size: cover;
        border-bottom: solid 0.35rem $white;
        height: 50vh;
        position: relative;

        &:after {
            background-color: #000;
            content: '';
            display: block;
            height: 100%;
            opacity: 0.5;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
        }

        &.story-listing-item--link {
            cursor: pointer;

            .content-wrapper {
                transition: 0.3s background-color ease-in-out;

                .arrow-link {
                    .icon-arrow-right {
                        &.icon-arrow-white {
                            color: white;
                        }
                    }
                }
            }

            &:hover {
                .content-wrapper {
                    background-color: rgb(0, 0, 0, 0.5);

                    .arrow-link {
                        padding-left: 10.25rem;

                        &:after {
                            width: 9.75rem;
                        }
                    }
                }
            }
        }

        &.border-red {
            border-bottom: solid 0.35rem $red;
        }

        &.border-none {
            border-bottom: none;
        }

        .container {
            position: relative;
            z-index: 1;

            @include media-breakpoint-up(xl) {
                height: 100%;
            }

            > .row {
                @include media-breakpoint-up(xl) {
                    height: 100%;
                }
            }
        }

        .header {
            margin-top: -1.35rem;
        }
    }
}
