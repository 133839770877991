.search-toolbar {
    .icon-magnify {
        font-size: 1.75rem;
        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }
    }

    .btn-container {
        position: relative;

        &:after {
            background: transparent;
            content: "";
            display: block;
            height: 100%;
            left: -1rem;
            pointer-events: none;
            position: absolute;
            top: 0;
            width: calc(100% + 2rem);
        }

        .btn {
            position: relative;
            z-index: 1;

            .btn-search-text {
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                    line-height: 1.2em;
                }
            }

            .btn-toggle-text {
                display: block;

                @include media-breakpoint-up(md) {
                    display: none;
                }
            }
        }
    }

    .search-toolbar__fields {
        max-height: 0;
        transition: all 350ms cubic-bezier(0.215, 0.61, 0.355, 1);
        opacity: 0;
        @include media-breakpoint-up(md) {
            max-height: 100%;
            opacity: 1;
        }

        .close-toggle {
            cursor: pointer;
            height: 35px;
            margin: 0 auto;
            position: relative;
            width: 35px;
            opacity: 0;
            transition: opacity 1500ms cubic-bezier(0.215, 0.61, 0.355, 1);

            span {
                display: block;
                background: #fff;
                border-radius: 2px;
            }

            #cross {
                height: 100%;
                position: absolute;
                transform: rotate(45deg);
                width: 100%;

                span {
                    transition: .2s ease-in-out;
                    box-sizing: border-box;

                    &:nth-child(1) {
                        width: 4px;
                        position: absolute;
                        top: 0;
                        left: 46%;
                        height: 100%;
                        transition-delay: .35s;
                    }

                    &:nth-child(2) {
                        width: 100%;
                        height: 4px;
                        position: absolute;
                        left: 0;
                        top: 46%;
                        transition-delay: 0.25s;
                    }
                }
            }
        }
    }

    &.search-toolbar--hero {
        background-color: rgb(40, 55, 69, 0.85);
        bottom: 0;
        width: 100%;
        position: absolute;
        z-index: 9;

        @include media-breakpoint-up(md) {
            z-index: 2;
        }

        form {
            .choices {
                .choices__inner {
                    .choices__list {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    &.open {
        @include media-breakpoint-down(md) {
            .search-toolbar__fields {
                max-height: 20rem;
                opacity: 1;

                .close-toggle {
                    opacity: 1;
                }
            }
        }

        .btn-container {
            .btn {
                .btn-search-text {
                    display: block;

                    @include media-breakpoint-up(md) {
                        display: none;
                    }
                }

                .btn-toggle-text {
                    display: none;

                    @include media-breakpoint-up(md) {
                        display: block;
                    }
                }
            }
        }
    }
}

.search {
    &-result {
        margin: 0 0 2rem;

        &-link {
            display: inline-block;
            text-decoration: none!important;

            &:hover,
            &:focus {
                text-decoration: none!important;
            }
        }

        .h2 {
            margin: 0 0 0.5rem;
            font-size: 24px;
            color: inherit;
        }

        &-url {
            margin: 0 0 0.5rem;
            text-decoration: underline;
            font-size: 14px;
        }

        &-desc {
            margin: 0 0 0.5rem;
            font-size: 12px;
        }
    }
}
