body.oc-loading,
body.oc-loading * {cursor:wait !important}
.stripe-loading-indicator {height:5px;background:transparent;position:fixed;top:0;left:0;width:100%;overflow:hidden;z-index:2000}
.stripe-loading-indicator .stripe,
.stripe-loading-indicator .stripe-loaded {height:5px;display:block;background:#0090c0;position:absolute;-webkit-box-shadow:inset 0 1px 1px -1px #FFF,inset 0 -1px 1px -1px #FFF;box-shadow:inset 0 1px 1px -1px #FFF,inset 0 -1px 1px -1px #FFF}
.stripe-loading-indicator .stripe {width:100%;-webkit-animation:oc-infinite-loader 60s linear;animation:oc-infinite-loader 60s linear}
.stripe-loading-indicator .stripe-loaded {width:100%;transform:translate3d(-100%,0,0);opacity:0;filter:alpha(opacity=0)}
.stripe-loading-indicator.loaded {opacity:0;filter:alpha(opacity=0);-webkit-transition:opacity 0.4s linear;transition:opacity 0.4s linear;-webkit-transition-delay:0.3s;transition-delay:0.3s}
.stripe-loading-indicator.loaded .stripe {animation-play-state:paused}
.stripe-loading-indicator.loaded .stripe-loaded {opacity:1;filter:alpha(opacity=100);transform:translate3d(0,0,0);-webkit-transition:transform 0.3s linear;transition:transform 0.3s linear}
.stripe-loading-indicator.hide {display:none}
body >p.flash-message {position:fixed;width:500px;left:50%;top:13px;margin-left:-250px;color:#fff;font-size:14px;padding:10px 30px 10px 15px;z-index:10300;word-wrap:break-word;text-shadow:0 -1px 0 rgba(0,0,0,0.15);text-align:center;-webkit-box-shadow:0 1px 6px rgba(0,0,0,0.12),0 1px 4px rgba(0,0,0,0.24);box-shadow:0 1px 6px rgba(0,0,0,0.12),0 1px 4px rgba(0,0,0,0.24);-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px}
body >p.flash-message.fade {opacity:0;filter:alpha(opacity=0);-webkit-transition:all 0.5s,width 0s;transition:all 0.5s,width 0s;-webkit-transform:scale(0.9);-ms-transform:scale(0.9);transform:scale(0.9)}
body >p.flash-message.fade.in {opacity:1;filter:alpha(opacity=100);-webkit-transform:scale(1);-ms-transform:scale(1);transform:scale(1)}
body >p.flash-message.success {background:#8da85e}
body >p.flash-message.error {background:#c30}
body >p.flash-message.warning {background:#f0ad4e}
body >p.flash-message.info {background:#5fb6f5}
body >p.flash-message button.close {float:none;position:absolute;right:10px;top:8px;color:white;font-size:21px;line-height:1;font-weight:bold;opacity:0.2;filter:alpha(opacity=20);padding:0;cursor:pointer;background:transparent;border:0;-webkit-appearance:none;outline:none}
body >p.flash-message button.close:hover,
body >p.flash-message button.close:focus {color:white;text-decoration:none;cursor:pointer;opacity:0.5;filter:alpha(opacity=50)}
@media (max-width:768px) {body >p.flash-message {left:10px;right:10px;top:10px;margin-left:0;width:auto }}
[data-request][data-request-validate] [data-validate-for]:not(.visible),
[data-request][data-request-validate] [data-validate-error]:not(.visible) {display:none}
a.oc-loading:after,
button.oc-loading:after,
span.oc-loading:after {content:'';display:inline-block;vertical-align:middle;margin-left:.4em;height:1em;width:1em;animation:oc-rotate-loader 0.8s infinite linear;border:.2em solid currentColor;border-right-color:transparent;border-radius:50%;opacity:0.5;filter:alpha(opacity=50)}
@-moz-keyframes oc-rotate-loader {0% {-moz-transform:rotate(0deg) }100% {-moz-transform:rotate(360deg) }}
@-webkit-keyframes oc-rotate-loader {0% {-webkit-transform:rotate(0deg) }100% {-webkit-transform:rotate(360deg) }}
@-o-keyframes oc-rotate-loader {0% {-o-transform:rotate(0deg) }100% {-o-transform:rotate(360deg) }}
@-ms-keyframes oc-rotate-loader {0% {-ms-transform:rotate(0deg) }100% {-ms-transform:rotate(360deg) }}
@keyframes oc-rotate-loader {0% {transform:rotate(0deg) }100% {transform:rotate(360deg) }}
@-moz-keyframes oc-infinite-loader {0% {transform:translateX(-100%) }10% {transform:translateX(-50%) }20% {transform:translateX(-25%) }30% {transform:translateX(-12.5%) }40% {transform:translateX(-6.25%) }50% {transform:translateX(-3.125%) }60% {transform:translateX(-1.5625%) }70% {transform:translateX(-0.78125%) }80% {transform:translateX(-0.390625%) }90% {transform:translateX(-0.1953125%) }100% {transform:translateX(-0.09765625%) }}
@-webkit-keyframes oc-infinite-loader {0% {transform:translateX(-100%) }10% {transform:translateX(-50%) }20% {transform:translateX(-25%) }30% {transform:translateX(-12.5%) }40% {transform:translateX(-6.25%) }50% {transform:translateX(-3.125%) }60% {transform:translateX(-1.5625%) }70% {transform:translateX(-0.78125%) }80% {transform:translateX(-0.390625%) }90% {transform:translateX(-0.1953125%) }100% {transform:translateX(-0.09765625%) }}
@-o-keyframes oc-infinite-loader {0% {transform:translateX(-100%) }10% {transform:translateX(-50%) }20% {transform:translateX(-25%) }30% {transform:translateX(-12.5%) }40% {transform:translateX(-6.25%) }50% {transform:translateX(-3.125%) }60% {transform:translateX(-1.5625%) }70% {transform:translateX(-0.78125%) }80% {transform:translateX(-0.390625%) }90% {transform:translateX(-0.1953125%) }100% {transform:translateX(-0.09765625%) }}
@-ms-keyframes oc-infinite-loader {0% {transform:translateX(-100%) }10% {transform:translateX(-50%) }20% {transform:translateX(-25%) }30% {transform:translateX(-12.5%) }40% {transform:translateX(-6.25%) }50% {transform:translateX(-3.125%) }60% {transform:translateX(-1.5625%) }70% {transform:translateX(-0.78125%) }80% {transform:translateX(-0.390625%) }90% {transform:translateX(-0.1953125%) }100% {transform:translateX(-0.09765625%) }}
@keyframes oc-infinite-loader {0% {transform:translateX(-100%) }10% {transform:translateX(-50%) }20% {transform:translateX(-25%) }30% {transform:translateX(-12.5%) }40% {transform:translateX(-6.25%) }50% {transform:translateX(-3.125%) }60% {transform:translateX(-1.5625%) }70% {transform:translateX(-0.78125%) }80% {transform:translateX(-0.390625%) }90% {transform:translateX(-0.1953125%) }100% {transform:translateX(-0.09765625%) }}