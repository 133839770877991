.cookies {
    &-btn {
        @include position-fixed("", 1rem, "", 1rem);
        width: 60px;
        height: 60px;
        background-color: #596672;
        padding: 12px;
        border-radius: 50%;
        z-index: 5;
    }

    &-popup {
        display: none;
        @include position-fixed(50%, 50%);
        transform: translate(-50%, -50%);
        max-width: 717px;
        width: 95%;
        max-height: 95%;
        padding: 25px;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(#000, 0.25);
        background: #fff;
        z-index: 100000000000;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        overflow: auto;

        &.is-open {
            display: block;
        }

        &-title {
            margin: 0 0 12px;
            font-weight: bold;
            text-transform: none;
        }

        &-accordion {
            &-section {
                border: 1px solid #d0d0d0;
                border-radius: 5px;
                margin-bottom: 10px;

                &-title {
                    padding: 10px 0 10px 10px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #444444;

                    @include media-breakpoint-up(sm) {
                        display: flex;
                        justify-content: space-between;
                    }

                    a {
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 13px;
                        font-weight: normal;
                        color: #a2a2a2;

                        &:hover,
                        &:focus {
                            color: #717171;
                        }

                        @include media-breakpoint-up(sm) {
                            padding: 0 10px;
                        }
                    }
                }
            }

            &-content {
                display: none;
                background: #fbfbfb;
                border-radius: 0 0 5px 5px;

                &-section {
                    padding: 15px;
                    border-top: 1px solid #e6e6e6;
                    color: #868686;
                }
            }
        }

        &-btns {
            margin-bottom: 15px;
            text-align: center;
        }

        &-btn {
            display: inline-block;
            padding: 7px 14px;
            font-size: 15px;
            border: 1px solid #cfcfcf;
            border-radius: 4px;
            color: #444;
            background: #f5f5f5;

            &:hover {
                background: #ececec;
            }

            &:focus {
                outline: none;
            }
        }

        &-footer {
            font-size: 13px;
            text-align: center;
            color: #868686;

            p {
                margin: 0 0 15px;
            }

            &-links {
                a {
                    display: inline-block;
                    font-size: 13px;
                    color: #a2a2a2;
                    text-decoration: none;

                    &:hover {
                        color: #717171;
                    }
                }
            }
        }

        &-content {
            &-switch {
                margin: 0 0 15px;
                font-size: 16px;
                color: #444444;
            }

            &-description {
                margin: 0 0 15px;
            }

            &-notice {
                font-size: 14px;
                line-height: 25.6px;
                background: #fff;
                padding: 10px;
                border-radius: 5px;
                border: 1px solid #efefef;
                color: #444444;
            }

            &-more {
                &-link {
                    display: inline-block;
                    margin: 15px 0;
                    font-size: 13px;
                    color: #a2a2a2;
                    text-decoration: underline;

                    &:hover,
                    &:focus {
                        color: #717171;
                        text-decoration: underline;
                    }
                }

                &-content {
                    display: none;
                    padding: 0 0 15px;

                    h3 {
                        margin: 20px 0 12px;
                        font-weight: bold;
                        text-transform: none;
                        color: #003a67;
                    }

                    p {
                        margin: 0;
                    }
                }
            }
        }
    }

    &-switch {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        top: -2px;
        margin: 0 5px 0 0;

        &.mod-half-switch {
            label {
                &:after {
                    background: linear-gradient(
                        to right,
                        #9c9b99 0%,
                        #9c9b99 50%,
                        #399d32 50%,
                        #399d32 100%
                    );
                }
            }
        }

        &-label {
            position: relative;
            display: block;
            width: 39px;
            height: 22px;
            margin: 0;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 35px;
                height: 18px;
                margin: 0;
                box-sizing: content-box;
                border-radius: 10px;
                background: #fff;
                border: 2px solid #9c9b99;
                transition: border-color 0.2s;
            }

            &:after {
                content: "";
                position: absolute;
                top: 4px;
                left: 4px;
                width: 14px;
                height: 14px;
                display: block;
                border-radius: 10px;
                background: #9c9b99;
                transition: background 0.2s, margin-left 0.2s, padding 0.2s;
            }
        }

        &-input {
            display: none;

            &:checked {
                + label {
                    &:before {
                        background: #dcf3db;
                        border-color: #399d32;
                    }

                    &:after {
                        background: #399d32;
                        margin-left: 17px;
                    }
                }
            }

            &:disabled {
                + label {
                    &:before {
                        background: #f4f4f4;
                        border-color: #c8c7c5;
                    }

                    &:after {
                        background: #c8c7c5;
                    }
                }
            }
        }
    }
}
