.vacancy-listing {
    .vacancy-listing__link {

        p {
            color: #212529
        }

        .arrow-link {
            width: 100%;
            margin-top: 0;
        }

        &:hover {
            text-decoration: none;

            .arrow-link {
                &.left-position, &.link {
                    padding-left: 10.25rem;
                }

                &:after {
                    width: 9.75rem;
                }
            }
        }
    }
}
