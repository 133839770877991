// -----------------------------------------------------------------------------
// This file contains CSS helper or utility classes.
// -----------------------------------------------------------------------------

/**
  Position classes
 */
.pos-r {
    position: relative;
}

.pos-a {
    position: absolute;
}

.pos-s {
    position: static;
}

/**
  Border classes
 */
.border-none {
    border: none;
}

/**
  Background classes
 */
.br-no-repeat {
    background-repeat: no-repeat;
}

.br-repeat-x {
    background-repeat: repeat-x;
}

.br-repeat-y {
    background-repeat: repeat-y;
}

.bp-bottom {
    background-position: bottom;
}

.bp-top-center {
    background-position: top center;
}

.bp-center {
    background-position: center;
}

.bs-cover {
    background-size: cover;
}

/**
  Text decoration classes
 */
.td-none {
    text-decoration: none;
}

.td-underline {
    text-decoration: underline;
}

/**
  Overflow classes
*/
.overflow-hidden {
    overflow: hidden;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

/**
  Font weight classes
 */
.fw-800 {
    font-weight: 800;
}

.fw-700 {
    font-weight: 700;
}

.fw-600 {
    font-weight: 600;
}

.fw-500 {
    font-weight: 500;
}

.fw-400 {
    font-weight: 400;
}

.fw-300 {
    font-weight: 300;
}

.z-1 {
    z-index: 1;
}

.z-2 {
    z-index: 2;
}

.z-3 {
    z-index: 3;
}

/**
  Colour helpers
 */
@each $colour-name, $colour in $colors {
    .#{$colour-name}-background {
        background-color: $colour;
    }

    .#{$colour-name}-text {
        color: $colour;
    }

    .#{$colour-name}-border-top {
        border-top: solid 0.5rem $colour;
    }

    .#{$colour-name}-border-bottom {
        border-bottom: solid 0.5rem $colour;
    }

    .#{$colour-name}-border-left {
        border-left: solid 0.5rem $colour;
    }

    .#{$colour-name}-border-right {
        border-right: solid 0.5rem $colour;
    }

    .#{$colour-name}-border-all-thin {
        border: solid 2px $colour;
    }

    .#{$colour-name}-border-top-thin {
        border-top: solid 2px $colour;
    }

    .#{$colour-name}-border-bottom-thin {
        border-bottom: solid 2px $colour;
    }

    .#{$colour-name}-border-left-thin {
        border-left: solid 2px $colour;
    }

    .#{$colour-name}-border-right-thin {
        border-right: solid 2px $colour;
    }
}

/* Responsive Height Classes */

$heightValues: 100, 95, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20;

@each $vhVal in $heightValues {
    .h-#{nth($vhVal, 1)} {
        min-height: #{nth($vhVal, 1)}#{"%"} !important;
    }

    .h-vh-#{nth($vhVal, 1)} {
        min-height: #{nth($vhVal, 1)}vh !important;
    }

    .h-vh-md-#{nth($vhVal, 1)} {
        @include media-breakpoint-down(md) {
            min-height: #{nth($vhVal, 1)}vh !important;
        }
    }

    .h-vh-xs-#{nth($vhVal, 1)} {
        @include media-breakpoint-down(sm) {
            min-height: #{nth($vhVal, 1)}vh !important;
        }
    }
}

.h-vh-md-auto {
    @include media-breakpoint-down(md) {
        height: auto !important;
    }
}

.h-vh-xs-auto {
    @include media-breakpoint-down(sm) {
        height: auto !important;
    }
}

.w-90 {
    width: 90%;
}

.mw-75 {
    max-width: 75%;
}

// Position mixin
@mixin position($top: "", $left: "", $right: "", $bottom: "") {
    @if $top != "" {
        top: $top;
    }
    @if $left != "" {
        left: $left;
    }
    @if $right != "" {
        right: $right;
    }
    @if $bottom != "" {
        bottom: $bottom;
    }
}

@mixin position-absolute($top: "", $left: "", $right: "", $bottom: "") {
    position: absolute;
    @include position($top, $left, $right, $bottom);
}

@mixin position-relative($top: "", $left: "", $right: "", $bottom: "") {
    position: relative;
    @include position($top, $left, $right, $bottom);
}

@mixin position-fixed($top: "", $left: "", $right: "", $bottom: "") {
    position: fixed;
    @include position($top, $left, $right, $bottom);
}

/* IE */
.ie-only {
    display: none;
}

@media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    .ie-only {
        top: 0;
        z-index: 99;
        display: block;
    }
}
