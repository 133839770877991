form {
    .choices {

        &[data-type*=select-one] {
            &:after {
                content: "\F077";
                font-family: "FontAwesome";
                color: $white;
                height: 0;
                width: 0;
                border-style: solid;
                border: none;
                position: absolute;
                right: 1.5rem;
                top: -5px;
                margin-top: 0;
                pointer-events: none;
                transition: all 0.15s ease-in-out;
            }
        }

        &:hover {
            &[data-type*=select-one] {
                &:after {
                    color: $primary;
                    transition: all 0.15s ease-in-out;
                }
            }
        }

        &:before {
            content: "/";
            font-size: 1.25rem;
            color: $white;
            position: absolute;
            left: 1px;
        }

        .choices__list--dropdown {
            background-color: $secondary;
            bottom: 0;

            .choices__item--selectable {
                word-break: break-word;
                padding-right: 0;

                &.is-highlighted {
                    background: #fff;
                    color: $secondary;
                }
            }

            .choices__item {
                color: #fff;
                font-weight: 600;

                &:hover {
                    color: $secondary;
                    background-color: #fff;
                }
            }
        }

        .choices__inner {
            background: transparent;
            border: none;
            border-bottom: solid 1px #fff;
            min-height: 0;
            border-radius: 0;
            padding-top: 0;
            padding-left: 1rem;
            padding-bottom: 1rem;

            .choices__list {
                background-color: $secondary;
                padding: 0;

                .choices__list--single {
                    padding: 0;
                }

                .choices__item {
                    color: #fff;
                    font-weight: 600;
                }
            }
        }
    }

    textarea {
        background: none;
        -webkit-appearance: none;
        border: none;
        border-bottom: solid 3px $secondary;
        font-size: 1.25rem;
        padding-bottom: 0.25rem;
        padding-left: 0.25rem;
        width: 100%;
        height: 100px;
        resize: none;
        overflow-y: auto;

        &:focus {
            outline: none;
        }
    }

    .file-upload {
        cursor: pointer;

        &::-webkit-file-upload-button {
            visibility: hidden;
        }

        &:before {
            content: '\f093';
            font-family: FontAwesome;
            display: inline-block;
            font-weight: 700;
            font-size: 1.5em;
            color: $secondary;
        }

        &:hover {
            &:before {
                color: $primary;
            }
        }
    }

    .custom-checkbox {
        span {
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 20px;
                height: 20px;
                top: 0;
                left: 0;
                background: #fdfdfd;
                border: 1px solid #000;
            }
        }

        input[type="checkbox"] {
            &:checked + span {
                &:before {
                    content: '\f00c';
                    font-family: FontAwesome;
                    font-size: 1.25em;
                    line-height: 1em;
                }
            }
        }
    }
}

.custom-input {
    appearance: none;
    color: #fff;
    border-bottom: 1px solid #fff;
    display: block;
    width: 100%;

    &:focus {
        border-bottom: 1px solid #fff!important;
    }

    &::placeholder {
        color: #fff!important;
    }
}

.form-group {
    position: relative;

    &.mod-append-btn {
        .form-control {
            height: 40px;
            border-radius: 6px;
            border-width: 0!important;
            box-shadow: 0 2px 4px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
        }

        .btn {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0;
            width: 40px;
            height: 40px;
            background: #AC163C;
            border-radius: 0 6px 6px 0;
        }

        .svg {
            margin: 2px 0 0;
            width: 18px;
            height: 18px;
        }

        path {
            fill: #fff;
        }
    }
}
