// -----------------------------------------------------------------------------
// This file contains very basic styles for standard HTML elements
// -----------------------------------------------------------------------------

//a {
//    color: blue;
//    text-decoration: none;
//}

p {
	@include media-breakpoint-up(md) {
		//@include font-size(1.25rem);
		line-height: 1.6em;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	word-break: break-word;
}

h1 {
}

h2,
.h2 {
	@include font-size(4rem);
}

input {
	background: none;
	-webkit-appearance: none;
	border: none;
	border-bottom: solid 3px $secondary;
	//font-size: 1.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.25rem;

	&:active,
	&:focus {
		border: none;
		border-bottom: solid 3px $secondary;
		outline: none;
	}

	&::-webkit-input-placeholder {
		color: $blue;
		font-weight: 600;
	}
}

section {
	&.section {
		position: relative;

		&.section--padded {
			padding: 4rem 0;

			@include media-breakpoint-up(md) {
				padding: 8rem 0;
			}
			/*
                  @include media-breakpoint-up(md) {
                    padding: 4rem 0;
                  }*/
		}

		&.section--padded-lg {
			padding: 14rem 0;

			@include media-breakpoint-down(md) {
				padding: 8rem 0;
			}
		}
	}
}

.textarea-label {
	font-weight: 600;
}

div.spacer {
	width: 30px;
	border-top: 2px solid $primary;
	opacity: 0.5;
}

.address-details {
	p {
		margin-bottom: 0.5rem;
	}
}

#map {
	height: 650px;
}

a {
	&.page-nav {
		color: white;
		transition: color 800ms cubic-bezier(0.215, 0.61, 0.355, 1);

		&:hover {
			color: $primary;
			text-decoration: none;
		}
	}
}

footer {
	.logo-image {
		gap: 1rem 0;
	}

	.office-list {
		p {
			font-size: 1rem;
			margin: 0;
		}
	}
}

.footer {
	padding-top: 4rem !important;
	@include media-breakpoint-up(lg) {
		padding-top: 6rem !important;
	}
}

#firstHeading {
	p {
		margin-bottom: 0;
	}
}

.service-block {
	background: #fff;
	padding: 80px 30px 20px;
	border-left: 16px solid #a51934;

	&.mod-navy {
		border-color: #263845;
	}

	h3 {
		margin: 0 0 20px;
		font-size: 40px;
	}

	&-wrapper {
		margin: 0 0 30px;
	}
}
