.image-content-grid {

    .image-content-grid__item {
        overflow: hidden;

        .image-content-grid__item-header {
            @include font-size(2.5rem);

            @include media-breakpoint-up(xl) {
                @include font-size(3rem);
                line-height: 1em;
            }
        }

        .content-wrapper {
            position: relative;
            z-index: 2;
        }

        &.image-content-grid__item--square {
            .content-wrapper {
                width: 100%;

                &:after {
                    content: "";
                    display: block;
                    padding-bottom: 100%;
                }

                .content {
                    height: 100%;
                    position: absolute;
                    width: 100%;
                    z-index: 2;
                }
            }
        }

        &.image-content-grid__item--link {
            &:hover {
                text-decoration: none;
            }

            cursor: pointer;

            a {
                .content-wrapper {
                    transition: 0.3s background-color ease-in-out;
                    background-color: rgba(0, 0, 0, 0.6);
                }

                &.content-grid-link {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            &:hover {
                .content-wrapper {
                    background-color: rgb(0, 0, 0, 0.5);

                    &.no-hover {
                        background-color: transparent;
                        cursor: default;
                    }
                }

                .arrow-link {
                    &.left-position, &.link {
                        padding-left: 10.25rem;
                    }

                    &:after {
                        width: 9.75rem;
                    }
                }
            }
        }

        > .row:first-of-type {
            background-size: cover;
            background-position: top center;
            position: relative;

            &:after {
                display: block;
                content: '';
                opacity: 0.3;
                top: 0;
                height: 100%;
                left: 0;
                position: absolute;
                width: 100%;
            }
        }

        .link {
            display: block;
            line-height: 1em;
            margin: 1rem 0;
            padding-left: 1.25rem;
            position: relative;
            transition: padding-left 1s ease;

            &:after {
                content: "";
                display: block;
                height: 100%;
                width: 0.75rem;
                background-color: #a51a35;
                top: 0;
                left: 0;
                position: absolute;
            }

            &:hover {
                text-decoration: none;
            }

            i {
                color: $secondary;
                font-size: 1.5rem;

                &.icon-arrow-white {
                    color: white;
                }
            }
        }
    }

    .arrow-link {
        a {
            i {
                font-size: 1.75rem;
            }
        }
    }
}
