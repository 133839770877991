.fr-kelly-menu-container {
    pointer-events: none;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 6;

    .fr-kelly-menu {
        pointer-events: all;
        position: absolute;
        overflow: hidden;
        transition: right 600ms cubic-bezier(0.215, 0.61, 0.355, 1);
        height: calc(100vh);
        right: -100%;
        top: 0;
        width: 100%;

        &.active {
            right: 0;
        }

        //&:after {
        //    content: "";
        //    display: block;
        //    height: 6rem;
        //    left: 0;
        //    position: absolute;
        //    bottom: 0;
        //    width: 100%;
        //    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,283745+9&0+0,1+40 */
        //    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(40, 55, 69, 0.23) 9%, rgba(40, 55, 69, 1) 40%); /* FF3.6-15 */
        //    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(40, 55, 69, 0.23) 9%, rgba(40, 55, 69, 1) 40%); /* Chrome10-25,Safari5.1-6 */
        //    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(40, 55, 69, 0.23) 9%, rgba(40, 55, 69, 1) 40%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        //    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#283745', GradientType=0); /* IE6-9 */
        //    z-index: 3;
        //}

        ul {
            font-weight: 700;
            list-style: none;
            margin: 0;
            padding: 0;
            position: relative;
            white-space: pre-line;
            display: inline;

            li {
                margin-bottom: 1.5rem;
                display: inline-block;
            }
        }

        nav {
            height: 100%;
            left: 0;
            top: 5rem;
            padding: 0 2rem 20vh 2rem;
            position: absolute;
            width: 100%;

            @include media-breakpoint-up(md) {
                top: 7rem;
            }

            &.primary-nav {

                ul {
                    display: block;
                    position: relative;
                    transition: all 0.5s ease;
                    transform: translateX(-40%);
                    opacity: 0.6;
                    width: 20rem;
                }

                &.active {

                    ul {
                        transform: translateX(0);
                        opacity: 1;
                    }
                }
            }

            &.sub-nav, .sub-nav-list {
                opacity: 0;
                overflow: scroll;
                padding-left: 17vw;
                pointer-events: none;
                transition: opacity 0.75s ease;

                &.active {
                    opacity: 1;
                    pointer-events: all;

                    a.back-btn {
                        bottom: 0;
                        color: $white;
                        display: block;
                        font-size: 1.25rem;
                        position: fixed;
                        z-index: 10;
                        padding-left: 17vw;

                        i {
                            display: block;
                            margin: 1rem 0;
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }
}
