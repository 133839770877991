.fr-kelly-menu {
    background: #283745;

    &:after {
        background: #283745;
    }

    nav {
        li {
            &.sub {
                font-family: "Montserrat", serif;
                @include font-size(1.25rem);
                color: #fff;
                line-height: 0.7em;
                font-weight: 600;
            }
        }
    }

    .primary-nav {
        li {
            margin-bottom: 0.75rem;

            a {
                @include font-size(2rem);
            }
        }
    }

    .sub-nav, .sub-nav-list {
        li {
            margin-bottom: 0.5rem;
            line-height: 1.75em;

            a {
                font-family: $headerFont;
                @include font-size(2.5rem);
                font-weight: 500;
            }
        }
    }

    ul {
        li {
            a {
                color: #fff;
                font-weight: 600;
            }
        }
    }
}
