.hero-image {
    background-size: cover;
    background-position: top center;
    height: 100vh;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        overflow: visible;
    }

    .page-header {
        position: absolute;
        top: 42%;
        text-align: center;
        width: 100%;
        z-index: 2;
    }

    &.small {
        height: 30vh;
    }

    &.medium {
        height: 45vh;
    }

    &.medium-large {
        height: 65vh;
    }

    &.large {
        height: 100vh;
    }

    &:after {
        background-color: #000;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.35;
        height: 100%;
        width: 100%;
    }
}
