// -----------------------------------------------------------------------------
// This file contains all custom typography styles
// -----------------------------------------------------------------------------

//h1 {
//    font-weight: 900;
//}

$headerFont: 'EB Garamond';
$bodyFont: $font-family-base;

.header-font {
    font-family: $headerFont, serif;
}

.body-font {
    font-family: $bodyFont, serif;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    font-family: $headerFont;
    font-weight: bold;
}

.small.validate {
    color: $red;
    font-size: 0.9rem;
}

.pagination {
    margin: 0;
    padding: 0;
    list-style-type: none;
    margin: 1rem 0 2.5rem;
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    text-align: center;

    li {
        display: inline-block;
        vertical-align: bottom;
        margin: 0 1.25rem 0 0;

        &:last-child {
            margin-right: 0;
        }
    }

    a, span {
        display: block;
        padding: 0.2rem 0.5rem;
        border-bottom: 3px solid #e0e0e0;
        color: #a51a35;
        text-decoration: none!important;

        &:hover,
        &:focus {
            border-color: #a51a35;
        }

    }

    span {
        border-color: #a51a35;
        color: #000;
    }
}
